<template>
  <!--  <b-overlay show spinner-variant="success" spinner-type="grow" spinner-small rounded="sm">-->
  <section class="auth">
    <div class="auth-container">
      <b-form @submit.prevent="handleLogin" class="auth-form">
        <b-overlay :show="isAuthLoading" spinner-variant="success" rounded="sm" opacity="0.3">
          <div class="text-center mb-2">
            
          </div>
          <h4 class="auth__title">Вход</h4>
          <b-form-group id="input-group-1" label="Номер телефона:" label-for="input-1">
            <b-form-input
              v-model.trim="phone"
              id="input-1"
              type="text"
              placeholder="+7 ("
              v-mask="'+7 (###) ### ## ##'"
              required
              :state="validatePhone"
            ></b-form-input>
            <b-form-invalid-feedback :state="validatePhone">
              Введите корректный номер телефона
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="input-group-2" label="Пароль:" label-for="input-2">
            <b-form-input
              v-model="password"
              id="input-2"
              type="password"
              placeholder="Введите пароль"
              required
              :state="validatePassword"
            ></b-form-input>
            <b-form-invalid-feedback :state="validatePassword">
              Пароль должен содержать минимум 4 символа
            </b-form-invalid-feedback>
          </b-form-group>
          <router-link :to="{ name: 'auth.forgot' }" class="text-success font-weight-bold ml-2"
            >Забыл пароль?
          </router-link>
          <div class="mt-4 text-center">
            <b-button type="submit" variant="success">Войти</b-button>
          </div>

          <div class="mt-3 text-center font-weight-bold">
            Не зарегистрированы? <br />
            <router-link :to="{ name: 'auth.registration' }" class="text-success">Зарегистрироваться </router-link>
          </div>

          <div class="mt-3 text-center font-weight-bold cursor-pointer text-success">
            <router-link :to="{ name: 'layout.main' }" class="text-success">Продолжить без авторизации</router-link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="ml-2"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </div>
        </b-overlay>
      </b-form>
    </div>
  </section>
</template>

<script>
import { mask } from 'vue-the-mask'
import Api from '@/services/api'

export default {
  name: 'AuthPage',
  directives: { mask },
  data: () => ({
    isAuthLoading: false,
    phone: '',
    password: '',
    isTouched: false,
  }),

  computed: {
    validatePhone() {
      if (!this.isTouched) return null
      return this.phone.length === 18
    },

    validatePassword() {
      if (!this.isTouched) return null
      return this.password.length >= 4
    },
  },

  mounted() {
    const isActivated = this.$route.query.activated
    if (isActivated) {
      this.makeToast(
        'Активация',
        'Аккаунт успешно активирован. Вы можете авторизоваться используя полученный смс-код как пароль',
        'success',
        true,
      )
      this.$router.replace({ query: null })
    }
  },

  methods: {
    async handleLogin() {
      this.isTouched = true
      if (!this.validateData()) return
      try {
        this.isAuthLoading = true
        const username = btoa(this.phone.replace(/[+() ]/g, ''))
        const password = btoa(this.password)
        const { user } = await this.wrapAndHandleRequest(() => Api.login({ username, password }))
        this.$store.commit('SET_USER', user)
        const redirectRouteName = this.$route.query.redirect ? this.$route.query.redirect : 'layout.main'
        await this.$router.push({ name: redirectRouteName })
        const { cart } = await this.wrapAndHandleRequest(Api.fetchCartList, false)
        const { favorites } = await this.wrapAndHandleRequest(Api.fetchFavoriteList, false)
        this.$store.commit('products/SET_FAVORITE_PRODUCTS', favorites)
        this.$store.commit('products/SET_BASKET_PRODUCTS', cart)
      } finally {
        this.isAuthLoading = false
      }
    },

    validateData() {
      return this.validatePhone && this.validatePassword
    },
  },
}
</script>
